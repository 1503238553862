<nav class="navbar">
  <h2 class="sr-only">Menu główne</h2>
  <cms-content
    [value]="menu"
    [loading]="isLoading"
    [error]="getError"
    (componentRefreshed)="onComponentRefresh()"
  >
    <div class="navbar-collapse">
      <ul class="list-unstyled list-inline menu">
        <li class="nav-item" (mouseover)="onMouseOver(item.id)" (mouseleave)="hideSubmenu()" *ngFor="let item of menu">
          <ng-container *ngIf="item.attributes.children.length > 0">
            <a
              class="nav-link "
              href="#"
              [class.highlight]="item.attributes.isHighlighted"
              [id]="item.attributes.title"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ item.attributes.title }}
            </a>

            <div class="dropdown-menu" [style.width.rem]="item.attributes.children.length > 12 ? '60' : '30'" [class.show]="open[item.id]" [attr.aria-labelledby]="item.attributes.title">
              <div class="layer">
                <ng-container *ngFor="let child of item.attributes.children">
                  <ng-container *ngIf="child.attributes.highlightedContent; else defaultTemplate">
                    <div class="highlighted-box">
                      <p>{{ child.attributes.highlightedContent }}</p>

                      <ng-container *ngIf="child.attributes.type === 'link'; else defaultInnerRouterLink">
                        <a
                          class="dropdown-item"
                          [class.highlight]="child.attributes.isHighlighted"
                          [attr.href]="child.attributes.url"
                          target="_blank"
                        >
                          {{ child.attributes.title }}</a
                        >
                      </ng-container>


                      <ng-template #defaultInnerRouterLink>
                        <a
                          class="dropdown-item"
                          [class.highlight]="child.attributes.isHighlighted"
                          [routerLink]="['/' + child.attributes.url]"
                        >
                          {{ child.attributes.title }}</a
                        >
                      </ng-template>

                    </div>
                  </ng-container>

                  <ng-template #defaultTemplate>
                    <ng-container *ngIf="child.attributes.type === 'link'; else innerRouterLink">
                      <a
                        class="dropdown-item"
                        target="_blank"
                        (click)="hideSubmenu()"
                        [attr.href]="child.attributes.url"
                        [class.highlight]="child.attributes.isHighlighted"
                      >
                        {{ child.attributes.title }}</a
                      >
                    </ng-container>

                    <ng-template #innerRouterLink>
                      <a
                        class="dropdown-item"
                        (click)="hideSubmenu()"
                        [class.highlight]="child.attributes.isHighlighted"
                        [routerLink]="['/' + child.attributes.url]"
                      >
                        {{ child.attributes.title }}</a
                      >
                    </ng-template>
                  </ng-template>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="item.attributes.children.length === 0">
            <ng-container *ngIf="item.attributes.type === 'link'; else innerRouterLink">
              <a
                class="nav-link"
                [class.highlight]="item.attributes.isHighlighted"
                target="_blank"
                [attr.href]="item.attributes.url"
                >{{ item.attributes.title }}</a
              >
            </ng-container>

            <ng-template #innerRouterLink>
              <a
                class="nav-link"
                [class.highlight]="item.attributes.isHighlighted"
                [routerLink]="['/' + item.attributes.url]"
                >{{ item.attributes.title }}</a
              >
            </ng-template>
          </ng-container>
        </li>
      </ul>
    </div>
  </cms-content>
</nav>
